import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { FunctionComponent, Fragment } from 'react';

import { ScheduleSummaryDTO } from '../../common/dtos/lti/ScheduleSummaryDTO';

export const LTI_JOB_TYPES = {
  rawData: I18n.t('Raw Data'),
  rostering: I18n.t('Roster Import'),
  rosterCompare: I18n.t('Roster Compare'),
  remapSisIds: I18n.t('Remap SIS IDs'),
  assignment: I18n.t('Grade Passback - Course'),
  gpbAccount: I18n.t('Grade Passback - Account'),
  accountReport: I18n.t('Account Report'),
  grade: I18n.t('Account Report - Grade Export'),
  assignmentGPBAccount: I18n.t('Account Report - SIS Submissions'),
  onerosterCsvGpb: I18n.t('Account Report - SIS Submissions - OneRoster'),
  pauseAgents: I18n.t('Pause Agents'),
  chores: I18n.t('Chores'),
  resetIntegrationData: I18n.t('Reset Integration Data'),
  disableSyncToSis: I18n.t('Disable Assignment Sync to SIS'),
  kimonoAssignmentGPB: I18n.t('Grade Sync'),
  kimonoCumulativeGPB: I18n.t('Grade Sync'),
  kimonoCategoryExchange: I18n.t('Category Sync'),
};

type Props = {
  schedule: ScheduleSummaryDTO;
};

const ScheduleName: FunctionComponent<Props> = (props: Props) => {
  const { schedule } = props;
  const type = get(LTI_JOB_TYPES, schedule.type, schedule.type);
  const name = get(schedule, 'name');
  const words = [];

  if (name) {
    words.push(name);
  }
  words.push(type);
  if (schedule.rosteringMode === 'spawn_job_per_school') {
    words.push(I18n.t('Spawn Job Per School'));
  }

  return <Fragment>{words.join(' - ')}</Fragment>;
};

export default ScheduleName;
