import { Tabs as InstTabs } from '@instructure/ui-tabs';
import isNumber from 'lodash/isNumber';
import React, { ReactNode, useEffect, useState } from 'react';

type Tab = {
  id: string;
  title: ReactNode;
  content: ReactNode;
  isDisabled?: boolean;
};

type Props = {
  tabs: Array<Tab>;
  selectedIndex?: number;
};

type TabOptions = {
  index: number;
  id: string;
};

const Tabs: React.FC<Props> = (props) => {
  const { selectedIndex, tabs } = props;
  const [tabIndex, setTabIndex] = useState(selectedIndex || 0);

  const handleTabChange = (
    e: React.SyntheticEvent<HTMLButtonElement>,
    { index }: TabOptions,
  ): void => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (isNumber(selectedIndex) && selectedIndex >= 0 && selectedIndex !== tabIndex) {
      setTabIndex(selectedIndex);
    }
  }, [selectedIndex]);

  return (
    <InstTabs onRequestTabChange={handleTabChange}>
      {tabs.map(({ id, title, content, isDisabled }, index) => (
        <InstTabs.Panel
          key={id}
          id={id}
          renderTitle={title}
          isSelected={tabIndex === index}
          isDisabled={isDisabled}
          padding="large small"
        >
          {content}
        </InstTabs.Panel>
      ))}
    </InstTabs>
  );
};

export default Tabs;
