import React, { Component, ReactNode } from 'react';

import { AsyncState, StrictPaginatedData } from '../redux/async';
import { Message } from '../types';

import Select, { LoadOptionsState, SelectOption } from './Select';

export type GetDataOptions = {
  nextUrl?: string;
  query?: string;
};

export type Props<T> = {
  onChange: (optionId: string) => void;
  renderLabel: ReactNode;
  renderOption: (item: T) => SelectOption;
  getData: (options: GetDataOptions) => void;
  getDataState: AsyncState<StrictPaginatedData<T>>;
  interaction?: 'readonly' | 'enabled' | 'disabled';
  defaultData?: Array<T>;
  layout?: 'inline';
  messages?: Array<Message>;
  selectedOption?: {
    idKey: keyof T;
    value: T;
  };
};

export default class PaginatedSelect<T> extends Component<Props<T>> {
  componentDidMount(): void {
    this.loadData({});
  }

  loadData = (options: GetDataOptions): void => {
    const { defaultData, getDataState } = this.props;

    if (!defaultData && !getDataState.pending) {
      this.props.getData(options);
    }
  };

  getLoadOptionsState(): LoadOptionsState | undefined {
    const { getDataState } = this.props;

    if (getDataState.pending) {
      return LoadOptionsState.IsLoading;
    }
    if (getDataState.data?.links.next) {
      return LoadOptionsState.CanLoadMore;
    }
  }

  handleQueryOptions = (query?: string): void => {
    const { getDataState } = this.props;

    if (query) {
      this.loadData({ query });
    } else if (getDataState.data?.params?.filter) {
      // reset options
      this.loadData({});
    }
  };

  render(): ReactNode {
    const {
      getDataState,
      selectedOption,
      onChange,
      renderLabel,
      renderOption,
      interaction,
      defaultData,
      messages,
    } = this.props;
    let options = defaultData || getDataState.data?.data || [];

    if (selectedOption) {
      options = options.find(
        (option) => option[selectedOption.idKey] === selectedOption.value[selectedOption.idKey],
      )
        ? options
        : [...options, selectedOption.value];
    }

    return (
      <Select
        renderLabel={renderLabel}
        selectedOptionId={
          selectedOption ? String(selectedOption.value[selectedOption.idKey]) : undefined
        }
        options={options.map(renderOption)}
        onChange={(option) => onChange(option.id)}
        layout={this.props.layout}
        interaction={interaction}
        loadOptionsState={this.getLoadOptionsState()}
        onLoadMoreOptions={() => this.loadData({ nextUrl: getDataState.data?.links.next })}
        onQueryOptions={this.handleQueryOptions}
        messages={messages}
      />
    );
  }
}
