import {
  FormFieldGroup,
  Popover,
  View,
  Alert,
  List,
  TextArea,
  ScreenReaderContent,
} from '@instructure/ui';
import { Checkbox, CheckboxGroup } from '@instructure/ui-checkbox';
import { Grid } from '@instructure/ui-grid';
import { IconInfoLine } from '@instructure/ui-icons';
import { Text as InstText } from '@instructure/ui-text';
import I18n from 'i18n-js';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import React, { Component, ReactNode } from 'react';

import { GetIntegrationsParams } from '../../../app/redux/integration';
import { AggregatedIntegrationDetail } from '../../../common/dtos/integration';
import { ScheduleSummaryDTO } from '../../../common/dtos/lti/ScheduleSummaryDTO';
import { IntegrationDetails } from '../../../common/integrationDetails';
import { RolloverStatus } from '../../../common/rolloverStatus';
import { AsyncState, StrictPaginatedData } from '../../redux/async';
import { Message, RolloverDate, RolloverInfo } from '../../types';
import DateInput from '../DateInput';
import PaginatedSelect from '../PaginatedSelect';
import ScheduleName from '../ScheduleName';
import Spinner from '../Spinner';
import TextInput from '../TextInput';

import ConnectedAsyncModal from './AsyncModal';

export type MappedProps = {
  integrationsState?: AsyncState<StrictPaginatedData<AggregatedIntegrationDetail>>;
  rosteringSchedulesPending: boolean;
  rosteringSchedules?: Array<ScheduleSummaryDTO>;
  showIntegrationSelector: boolean;
};

type HOCProps = MappedProps & {
  getRosteringSchedules: (integrationId?: string) => Promise<void>;
  getIntegrations: (url?: string, params?: GetIntegrationsParams) => Promise<void>;
};

export type OwnProps = {
  rollover?: RolloverDate;
  rosteringSchedulesPending: boolean;
  rosteringSchedules?: Array<ScheduleSummaryDTO>;
  existingRollovers: Array<RolloverDate>;
  timezone?: string;
  upsertRollover: (rolloverInfo: RolloverInfo) => void;
};

export type Props = HOCProps & OwnProps;

type State = {
  rolloverName: string;
  syncPauseDate: DateTime | undefined;
  syncResumeDate: DateTime | undefined;
  rolloverNotes?: string;
  selectedSchedules: Array<string>;
  integrationId: string;
  integrationAccountName?: string;
  timezone?: string;

  canvasAccountMessages: Array<Message>;
  rolloverNameMessages: Array<Message>;
  syncPauseDateMessages: Array<Message>;
  syncResumeDateMessages: Array<Message>;
  rolloverNotesMessages: Array<Message>;
  selectedSchedulesMessages: Array<Message>;
  globalMessages: Array<Message>;
};

enum InputKeys {
  CanvasAccount = 'canvasAccount',
  SyncPauseDate = 'syncPauseDate',
  SyncResumeDate = 'syncResumeDate',
  RolloverName = 'rolloverName',
  RolloverNotes = 'rolloverNotes',
  SelectedSchedules = 'selectedSchedules',
  Global = 'global',
}

export class CreateNewRolloverModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { rollover, timezone } = this.props;

    this.state = {
      rolloverName: rollover?.rolloverName || '',
      syncPauseDate: rollover?.syncPauseDate,
      syncResumeDate: rollover?.syncResumeDate,
      rolloverNotes: rollover?.notes,
      integrationId: rollover?.integrationDetails.id || '',
      integrationAccountName: rollover?.integrationDetails.accountName,
      timezone,

      canvasAccountMessages: [],
      rolloverNameMessages: [],
      rolloverNotesMessages: [],
      syncPauseDateMessages: [],
      syncResumeDateMessages: [],
      selectedSchedulesMessages: [],
      globalMessages: [],

      selectedSchedules: rollover?.affectedSchedules || [],
    };
  }

  componentDidMount(): void {
    this.getRosteringSchedules(this.state.integrationId);

    // SOS-2948: When the integration selector is shown and we are in edit mode,
    // we must load the related integration to get the timezone.
    if (this.props.showIntegrationSelector && this.props.rollover) {
      this.props.getIntegrations(undefined, {
        filter: this.props.rollover.integrationDetails.id,
      });
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (!this.props.showIntegrationSelector || !this.props.rollover || this.state.timezone) {
      return;
    }

    const previous = prevProps.integrationsState;
    const current = this.props.integrationsState;

    if (previous !== current && current?.pending === false) {
      this.handleIntegrationChanged(this.props.rollover.integrationDetails.id);
    }
  }

  getRosteringSchedules = (integrationId?: string) => {
    const { rosteringSchedulesPending, showIntegrationSelector } = this.props;

    if (!rosteringSchedulesPending && (!showIntegrationSelector || integrationId)) {
      this.props.getRosteringSchedules(integrationId);
    }
  };

  getErrorMessage = (text: string): Message => ({ text, type: 'error' });

  handleUpsert = (): boolean => {
    const {
      rolloverName,
      syncPauseDate,
      syncResumeDate,
      selectedSchedules,
      integrationId,
      rolloverNotes,
    } = this.state;

    if (!this.validateBeforeSave() || !syncPauseDate || !syncResumeDate) {
      return false;
    }

    this.props.upsertRollover({
      rolloverName,
      syncPauseDate,
      syncResumeDate,
      affectedSchedules: selectedSchedules,
      integrationDetails: {
        id: integrationId,
      },
      notes: rolloverNotes,
    });

    return true;
  };

  rolloverOverlapsWithOtherRollovers = (): boolean => {
    return (
      this.props.existingRollovers
        .filter((x) => x.id !== this.props.rollover?.id)
        .filter((existingRollover) => {
          const { syncPauseDate, syncResumeDate } = this.state;

          if (!syncPauseDate || !syncResumeDate) {
            return false;
          }

          if (
            existingRollover.syncPauseDate.toMillis() > syncResumeDate.toMillis() ||
            existingRollover.syncResumeDate.toMillis() < syncPauseDate.toMillis()
          ) {
            return false;
          }

          return true;
        }).length > 0
    );
  };

  getValidationMessages = (componentName: InputKeys, newValue?: any): Array<Message> => {
    const messages: Array<Message> = [];

    // when a component is not editable, do not validate it
    if (!this.canEditRollover(componentName)) {
      return messages;
    }

    const todayMidnight = DateTime.now().setZone(this.state.timezone).startOf('day').toMillis();

    if (componentName === InputKeys.CanvasAccount) {
      const integrationId = (newValue as string) || this.state.integrationId;

      if (!integrationId) {
        messages.push(this.getErrorMessage(I18n.t('Please select a Canvas Account')));
      }
    }

    if (componentName === InputKeys.SyncPauseDate) {
      const syncPauseDate = (newValue as DateTime) || this.state.syncPauseDate;

      if (!syncPauseDate) {
        messages.push(this.getErrorMessage(I18n.t('Please set a value for Sync Pause Date')));
      } else if (syncPauseDate.toMillis() < todayMidnight) {
        messages.push(
          this.getErrorMessage(I18n.t('Sync Pause Date cannot be set to a date in the past')),
        );
      } else if (this.state.syncResumeDate && syncPauseDate >= this.state.syncResumeDate) {
        messages.push(
          this.getErrorMessage(I18n.t('Sync Pause Date must be earlier than the Resume Date')),
        );
      }
    }

    if (componentName === InputKeys.SyncResumeDate) {
      const syncResumeDate = (newValue as DateTime) || this.state.syncResumeDate;

      if (!syncResumeDate) {
        messages.push(this.getErrorMessage(I18n.t('Please set a value for Sync Resume Date')));
      } else if (syncResumeDate.toMillis() < todayMidnight) {
        messages.push(
          this.getErrorMessage(I18n.t('Sync Resume Date cannot be set to a date in the past')),
        );
      } else if (this.state.syncPauseDate && syncResumeDate <= this.state.syncPauseDate) {
        messages.push(
          this.getErrorMessage(I18n.t('Sync Resume Date must be later than the Pause Date')),
        );
      }
    }

    if (componentName === InputKeys.RolloverName) {
      const rolloverName = newValue !== undefined ? newValue : this.state.rolloverName;

      if (!rolloverName) {
        messages.push(this.getErrorMessage(I18n.t('Please set the name of the Rollover')));
      }

      if (rolloverName.length > 255) {
        messages.push(this.getErrorMessage(I18n.t('The length must be 255 characters or fewer')));
      }
    }

    if (componentName === InputKeys.RolloverNotes) {
      const rolloverNotes = newValue !== undefined ? newValue : this.state.rolloverNotes;

      if (rolloverNotes && rolloverNotes.length > 2000) {
        messages.push(this.getErrorMessage(I18n.t('The length must be 2000 characters or fewer')));
      }
    }

    if (componentName === InputKeys.SelectedSchedules) {
      const selectedSchedules = (newValue as string[]) || this.state.selectedSchedules;

      if (selectedSchedules.length === 0) {
        messages.push(this.getErrorMessage(I18n.t('Please select at least one schedule')));
      }
    }

    if (componentName === InputKeys.Global) {
      //This is a hacky way to only evaluate this rule upon save, and not earlier.
      if (newValue === undefined && this.rolloverOverlapsWithOtherRollovers()) {
        messages.push(
          this.getErrorMessage(
            I18n.t(
              'The dates set in this rollover overlap with another rollover date. ' +
                'Please review your configuration and try again.',
            ),
          ),
        );
      }
    }

    return messages;
  };

  hasRolloverStarted = (): boolean => {
    if (!this.props.rollover) {
      return false;
    }

    return this.props.rollover.status !== RolloverStatus.Upcoming;
  };

  hasRolloverEnded = (): boolean => {
    if (!this.props.rollover) {
      return false;
    }

    return this.props.rollover.status === RolloverStatus.Completed;
  };

  canEditRollover = (componentName: InputKeys): boolean | undefined => {
    switch (componentName) {
      case InputKeys.Global:
        return true; // this is only passed when saving, not really a component
      case InputKeys.CanvasAccount:
        return this.props.showIntegrationSelector && !this.props.rollover;
      case InputKeys.RolloverNotes:
      case InputKeys.SyncResumeDate:
        return !this.hasRolloverEnded();
      default:
        return !this.hasRolloverStarted();
    }
  };

  validateBeforeSave = (): boolean => {
    const canvasAccountMessages = this.getValidationMessages(InputKeys.CanvasAccount);
    const syncPauseDateMessages = this.getValidationMessages(InputKeys.SyncPauseDate);
    const syncResumeDateMessages = this.getValidationMessages(InputKeys.SyncResumeDate);
    const rolloverNameMessages = this.getValidationMessages(InputKeys.RolloverName);
    const rolloverNotesMessages = this.getValidationMessages(InputKeys.RolloverNotes);
    const selectedSchedulesMessages = this.getValidationMessages(InputKeys.SelectedSchedules);
    const globalMessages = this.getValidationMessages(InputKeys.Global);

    this.setState({
      canvasAccountMessages,
      rolloverNameMessages,
      syncPauseDateMessages,
      syncResumeDateMessages,
      rolloverNotesMessages,
      selectedSchedulesMessages,
      globalMessages,
    });

    return [
      canvasAccountMessages,
      rolloverNameMessages,
      syncPauseDateMessages,
      syncResumeDateMessages,
      rolloverNotesMessages,
      selectedSchedulesMessages,
      globalMessages,
    ].every((x) => x.length === 0);
  };

  handleScheduleSelect = (id: string) => () => {
    const { selectedSchedules } = this.state;

    const newSelectedSchedules = selectedSchedules.includes(id)
      ? selectedSchedules.filter((x) => x !== id)
      : selectedSchedules.concat(id);

    this.setState({
      selectedSchedules: newSelectedSchedules,
      selectedSchedulesMessages: this.getValidationMessages(
        InputKeys.SelectedSchedules,
        newSelectedSchedules,
      ),
    });
  };

  handleIntegrationChanged = (integrationId: string) => {
    const integration = get(this.props.integrationsState, 'data.data', []).find(
      (integrationDetails: AggregatedIntegrationDetail) => integrationDetails.id === integrationId,
    );

    this.getRosteringSchedules(integrationId);

    this.setState({
      integrationId,
      integrationAccountName: integration?.accountName,
      canvasAccountMessages: this.getValidationMessages(InputKeys.CanvasAccount, integrationId),
      timezone: integration?.timezones[0] || this.state.timezone,
    });
  };

  handleNotesChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value || undefined;

    this.setState({
      rolloverNotes: newValue,
      rolloverNotesMessages: this.getValidationMessages(InputKeys.RolloverNotes, newValue),
    });
  };

  getDeletedSchedulesWarning = (): ReactNode | undefined => {
    const schedulesFromSavedRollover = this.props.rollover?.affectedSchedules || [];
    const existingSchedules = (this.props.rosteringSchedules || []).map((schedule) => schedule.id);

    const numberOfSchedulesDeleted = schedulesFromSavedRollover.filter(
      (id) => !existingSchedules.includes(id),
    ).length;

    return numberOfSchedulesDeleted < 1 ? undefined : (
      <Alert
        variant="warning"
        margin="0 0 medium"
        contentLineHeight
        theme={{
          boxShadow: 'none',
          contentLineHeight: '1.5rem',
        }}
      >
        {I18n.t('This rollover references %{n} deleted schedule(s) not displayed here', {
          n: numberOfSchedulesDeleted,
        })}
      </Alert>
    );
  };

  render(): ReactNode {
    const deletedSchedulesWarning = this.getDeletedSchedulesWarning();
    const rolloverStatus = this.props.rollover?.status;
    const modalHeader =
      rolloverStatus === RolloverStatus.Completed
        ? I18n.t('View Rollover')
        : rolloverStatus
          ? I18n.t('Edit Rollover')
          : I18n.t('Add New Rollover');
    const modalSaveButtonText =
      rolloverStatus === RolloverStatus.Completed
        ? undefined // -> button should be hidden
        : rolloverStatus
          ? I18n.t('Update')
          : I18n.t('Add');

    const syncPauseDateHelp = this.state.timezone
      ? I18n.t(
          'Synchronizations will be paused at midnight (00:00, timezone: %{timezone}) of the specified date',
          { timezone: this.state.timezone },
        )
      : I18n.t('Synchronizations will be paused at midnight of the specified date');
    const syncResumeDateHelp = this.state.timezone
      ? I18n.t(
          'Synchronizations will be resumed at midnight (00:00, timezone: %{timezone}) of the specified date',
          { timezone: this.state.timezone },
        )
      : I18n.t('Synchronizations will be resumed at midnight of the specified date');

    const notesHelp = I18n.t(
      'Optional; provided additional notes will be reviewed (and if necessary, acted on) by support',
    );
    const popoverPlacement = 'top center';

    return (
      <ConnectedAsyncModal
        label={modalHeader}
        modalClass="CreateNewRolloverModal"
        header={modalHeader}
        size="small"
        saveButtonText={modalSaveButtonText}
        saveButtonDataAttribute={rolloverStatus ? 'rollover-save-existing' : 'rollover-save-new'}
        onSave={this.handleUpsert}
        closeOnSave
      >
        <FormFieldGroup
          layout="stacked"
          rowSpacing="small"
          description=""
          messages={this.state.globalMessages}
        >
          <Grid>
            {this.props.showIntegrationSelector && (
              <Grid.Row margin="medium 0 0">
                <Grid.Col>
                  <PaginatedSelect
                    onChange={this.handleIntegrationChanged}
                    renderLabel={I18n.t('Canvas Account')}
                    renderOption={(integration: IntegrationDetails) => ({
                      id: integration.id,
                      label: integration.accountName || '',
                    })}
                    getData={({ nextUrl, query }) =>
                      this.props.getIntegrations(nextUrl, {
                        filter: query,
                      })
                    }
                    getDataState={
                      this.props.integrationsState as AsyncState<
                        StrictPaginatedData<IntegrationDetails>
                      >
                    }
                    interaction={
                      this.canEditRollover(InputKeys.CanvasAccount) ? 'enabled' : 'disabled'
                    }
                    messages={this.state.canvasAccountMessages}
                    selectedOption={{
                      idKey: 'id',
                      value: {
                        id: this.state.integrationId,
                        accountName: this.state.integrationAccountName,
                      },
                    }}
                  />
                </Grid.Col>
              </Grid.Row>
            )}
            <Grid.Row margin="medium 0 0">
              <Grid.Col>
                <TextInput
                  layout={'stacked'}
                  renderLabel={I18n.t('Rollover Name')}
                  defaultValue={this.state.rolloverName}
                  interaction={
                    this.canEditRollover(InputKeys.RolloverName) ? 'enabled' : 'disabled'
                  }
                  onChange={(name) =>
                    this.setState({
                      rolloverName: name,
                      rolloverNameMessages: this.getValidationMessages(
                        InputKeys.RolloverName,
                        name,
                      ),
                    })
                  }
                  messages={this.state.rolloverNameMessages}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row margin="medium 0 0">
              <Grid.Col>
                <DateInput
                  timezone={this.state.timezone}
                  date={this.state.syncPauseDate || ''}
                  display="block"
                  width="100%"
                  renderLabel={
                    <div>
                      {I18n.t('Sync Pause Date')}{' '}
                      <Popover renderTrigger={<IconInfoLine />} placement={popoverPlacement}>
                        <View padding="x-small" display="block" as="div">
                          {syncPauseDateHelp}
                        </View>
                      </Popover>
                    </div>
                  }
                  interaction={
                    this.canEditRollover(InputKeys.SyncPauseDate) ? 'enabled' : 'disabled'
                  }
                  assistiveText={syncPauseDateHelp}
                  onChange={(date) =>
                    this.setState({
                      syncPauseDate: date,
                      syncPauseDateMessages: this.getValidationMessages(
                        InputKeys.SyncPauseDate,
                        date,
                      ),
                    })
                  }
                  messages={this.state.syncPauseDateMessages}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row margin="medium 0 0">
              <Grid.Col>
                <DateInput
                  timezone={this.state.timezone}
                  date={this.state.syncResumeDate || ''}
                  display="block"
                  width="100%"
                  renderLabel={
                    <div>
                      {I18n.t('Sync Resume Date')}{' '}
                      <Popover renderTrigger={<IconInfoLine />} placement={popoverPlacement}>
                        <View padding="x-small" display="block" as="div">
                          {syncResumeDateHelp}
                        </View>
                      </Popover>
                    </div>
                  }
                  assistiveText={syncResumeDateHelp}
                  interaction={
                    this.canEditRollover(InputKeys.SyncResumeDate) ? 'enabled' : 'disabled'
                  }
                  onChange={(date) =>
                    this.setState({
                      syncResumeDate: date,
                      syncResumeDateMessages: this.getValidationMessages(
                        InputKeys.SyncResumeDate,
                        date,
                      ),
                    })
                  }
                  messages={this.state.syncResumeDateMessages}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row margin="medium 0 0">
              <Grid.Col>
                <TextArea
                  label={
                    <>
                      {I18n.t('Notes')}{' '}
                      <Popover renderTrigger={<IconInfoLine />} placement={popoverPlacement}>
                        <View padding="x-small" display="block" as="div">
                          {notesHelp}
                        </View>
                      </Popover>
                      <ScreenReaderContent>{'. ' + notesHelp}</ScreenReaderContent>
                    </>
                  }
                  messages={this.state.rolloverNotesMessages}
                  readOnly={!this.canEditRollover(InputKeys.RolloverNotes)}
                  value={this.state.rolloverNotes || ''}
                  onChange={this.handleNotesChanged}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                {this.props.rosteringSchedulesPending ? (
                  <Spinner inline={true}></Spinner>
                ) : (
                  <>
                    {this.hasRolloverStarted() && (
                      <FormFieldGroup description={I18n.t('Affected Schedules')} layout="columns">
                        <List isUnstyled={true} margin="0">
                          {this.props.rosteringSchedules?.map((schedule) => {
                            // for rollovers where schedules cannot be modified anymore, do not display checkbox,
                            // and only display the actually affected schedules, not all of them
                            if (this.state.selectedSchedules.includes(schedule.id)) {
                              return (
                                <List.Item key={schedule.id}>
                                  <InstText color="secondary" key={schedule.id}>
                                    <ScheduleName schedule={schedule} />
                                  </InstText>
                                </List.Item>
                              );
                            }
                            return;
                          })}
                        </List>
                      </FormFieldGroup>
                    )}
                    {!this.hasRolloverStarted() &&
                      (this.state.integrationId || !this.props.showIntegrationSelector) && (
                        <CheckboxGroup
                          name="affectedSchedules"
                          messages={this.state.selectedSchedulesMessages}
                          defaultValue={this.state.selectedSchedules}
                          description={I18n.t('Select Affected Schedules')}
                        >
                          {this.props.rosteringSchedules?.map((x) => (
                            <Checkbox
                              value={x.id}
                              disabled={!this.canEditRollover(InputKeys.SelectedSchedules)}
                              onChange={this.handleScheduleSelect(x.id)}
                              key={x.id}
                              label={<ScheduleName schedule={x} />}
                            />
                          ))}
                        </CheckboxGroup>
                      )}

                    {deletedSchedulesWarning}
                  </>
                )}
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </FormFieldGroup>
      </ConnectedAsyncModal>
    );
  }
}
