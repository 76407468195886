import { Alert } from '@instructure/ui';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';

import { RolloverStatus } from '../../../common/rolloverStatus';

import ConnectedAsyncModal from './AsyncModal';

export type Props = {
  rolloverStatus: RolloverStatus;
  deleteRollover: () => void;
};

class DeleteRolloverModal extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): ReactNode {
    const label = I18n.t('Confirm Rollover Deletion');

    return (
      <ConnectedAsyncModal
        label={label}
        modalClass="DeleteRolloverModal"
        header={label}
        size="small"
        saveButtonText={I18n.t('Delete')}
        saveButtonDataAttribute="rollover-delete"
        saveButtonColor="danger"
        onSave={this.props.deleteRollover}
        closeOnSave
      >
        {I18n.t('Are you sure you want to delete this rollover date?')}
        {this.props.rolloverStatus === RolloverStatus.Ongoing && (
          <Alert
            variant="warning"
            contentLineHeight
            theme={{
              boxShadow: 'none',
              contentLineHeight: '1.5rem',
            }}
          >
            {I18n.t(
              'When an ongoing rollover is deleted, its referenced schedules ' +
                'will remain paused. These schedules need to be ' +
                'unpaused (or recreated) manually.',
            )}
          </Alert>
        )}
      </ConnectedAsyncModal>
    );
  }
}

export default DeleteRolloverModal;
