import { Alert } from '@instructure/ui-alerts';
import { Flex } from '@instructure/ui-flex';
import { Select as InstSelect } from '@instructure/ui-select';
import { Spinner } from '@instructure/ui-spinner';
import { Text } from '@instructure/ui-text';
import I18n from 'i18n-js';
import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';
import React, { Component, Fragment, ReactNode } from 'react';

import { Message } from '../types';

export const QUERY_DELAY_MILLIS = 500;

export enum LoadOptionsState {
  IsLoading = '_is_loading',
  CanLoadMore = '_can_load_more',
}

type InstOption = {
  id: string;
};

export type SelectOption = {
  id: string;
  label: string;
  groupId?: string;
  groupName?: string;
  isPending?: boolean;
  isDisabled?: boolean;
  renderBeforeLabel?: ReactNode;
  renderOption?: (isHighlighted: boolean) => ReactNode;
};

export type Props = {
  renderLabel: ReactNode;
  selectedOptionId: string | null | undefined;
  options: Array<SelectOption>;
  grouped?: boolean;
  onChange?: (option: SelectOption) => void;
  layout?: string;
  interaction?: string;
  width?: string | number;
  messages?: Array<Message>;
  renderBeforeInput?: ReactNode;
  placeholder?: string;
  loadOptionsState?: LoadOptionsState;
  onLoadMoreOptions?: () => void;
  onQueryOptions?: (query?: string) => void;
};

export const renderEmptyOption = (): ReactNode => (
  <InstSelect.Option id="empty-option" isDisabled={true}>
    {I18n.t('No items found')}
  </InstSelect.Option>
);

type State = {
  query: string | null;
  inputValue: string;
  isShowingOptions: boolean;
  highlightedOptionId: string | null | undefined;
  announcement: string | null | undefined;
};

class Select extends Component<Props, State> {
  state: State = {
    query: null,
    inputValue: '',
    isShowingOptions: false,
    highlightedOptionId: null,
    announcement: null,
  };

  queryOptions = debounce((query?: string) => {
    if (this.props.onQueryOptions) {
      this.props.onQueryOptions(query && query.trim());
    }
  }, QUERY_DELAY_MILLIS);

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    this.setState(
      {
        query: value,
        inputValue: value,
        isShowingOptions: true,
      },
      () => this.queryOptions(value),
    );
  };

  handleShowOptions = (): void => {
    this.setState({
      isShowingOptions: true,
      announcement: I18n.t('List expanded'),
    });
  };

  handleHideOptions = (): void => {
    this.setState(
      {
        query: null,
        inputValue: '',
        isShowingOptions: false,
        announcement: I18n.t('List collapsed'),
      },
      () => this.queryOptions(),
    );
  };

  handleBlur = (): void => {
    this.setState({
      highlightedOptionId: null,
    });
  };

  findOption(optionId: string | null | undefined): SelectOption | undefined {
    return this.props.options.find((option) => option.id === optionId);
  }

  handleHighlightOption = (e: React.SyntheticEvent<HTMLInputElement>, option: InstOption): void => {
    e.persist();
    const found = this.findOption(option.id);
    const label = found && found.label;

    this.setState(({ inputValue }) => {
      return {
        // only update input value if using keyboard navigation
        inputValue:
          label && e.type === 'keydown' && option.id !== LoadOptionsState.CanLoadMore
            ? label
            : inputValue,
        highlightedOptionId: option.id,
      };
    });
  };

  handleSelectOption = (e: React.SyntheticEvent<HTMLInputElement>, option: InstOption): void => {
    const { onChange, onLoadMoreOptions } = this.props;

    if (onLoadMoreOptions && option.id === LoadOptionsState.CanLoadMore) {
      this.setState({
        announcement: I18n.t('Load More'),
      });
      onLoadMoreOptions();
      return;
    }

    const found = this.findOption(option.id);

    if (!found) {
      return;
    }

    this.setState(
      {
        query: null,
        inputValue: '',
        isShowingOptions: false,
        announcement: I18n.t('%{option} selected', {
          option: found.label,
        }),
      },
      () => this.queryOptions(),
    );

    if (onChange) {
      onChange(found);
    }
  };

  renderOption(option: SelectOption): ReactNode {
    const { selectedOptionId } = this.props;
    const { highlightedOptionId } = this.state;
    const { id, label, isPending, renderBeforeLabel, isDisabled, renderOption } = option;
    const isHighlighted = id === highlightedOptionId;
    const isSelected = id === selectedOptionId;
    const rendered = renderOption ? renderOption(isHighlighted || isSelected) : label;

    return (
      <InstSelect.Option
        key={id}
        id={id}
        isHighlighted={isHighlighted}
        isSelected={isSelected}
        renderBeforeLabel={renderBeforeLabel}
        isDisabled={isDisabled || isPending}
      >
        {isPending ? <Spinner renderTitle={I18n.t('Loading')} size="x-small" /> : rendered}
      </InstSelect.Option>
    );
  }

  renderLoadMoreOption(loadOptionsState: LoadOptionsState): ReactNode {
    const { highlightedOptionId } = this.state;
    const isHighlighted =
      loadOptionsState === LoadOptionsState.CanLoadMore &&
      highlightedOptionId === LoadOptionsState.CanLoadMore;

    return (
      <InstSelect.Option id={loadOptionsState} isHighlighted={isHighlighted}>
        <Flex justifyItems="center">
          {loadOptionsState === LoadOptionsState.IsLoading ? (
            <Spinner renderTitle={I18n.t('Loading')} size="x-small" />
          ) : (
            <Text color={isHighlighted ? 'primary-inverse' : 'brand'}>{'Load More'}</Text>
          )}
        </Flex>
      </InstSelect.Option>
    );
  }

  renderGroupedOptions(options: Array<SelectOption>): ReactNode {
    const grouped = groupBy(options, (element) => element.groupId);

    return Object.keys(grouped).map((groupId) => (
      <InstSelect.Group key={groupId} id={groupId} renderLabel={grouped[groupId][0].groupName}>
        {grouped[groupId].map((option) => this.renderOption(option))}
      </InstSelect.Group>
    ));
  }

  renderSimpleOptions(options: Array<SelectOption>): ReactNode {
    return options.map((option) => this.renderOption(option));
  }

  renderOptions(): ReactNode {
    const { options, grouped, onQueryOptions } = this.props;
    const { query } = this.state;
    const filteredOptions =
      query && !onQueryOptions
        ? options.filter(({ label }) => label.toLowerCase().startsWith(query.toLowerCase()))
        : options;

    if (filteredOptions.length === 0) {
      return renderEmptyOption();
    }
    return grouped
      ? this.renderGroupedOptions(filteredOptions)
      : this.renderSimpleOptions(filteredOptions);
  }

  render(): ReactNode {
    const {
      renderLabel,
      grouped,
      selectedOptionId,
      loadOptionsState,
      onLoadMoreOptions,
      onQueryOptions,
      ...selectProps
    } = this.props;
    const { query, inputValue, isShowingOptions, announcement } = this.state;
    const found = this.findOption(selectedOptionId);
    const label = found && found.label;
    const showingInputValue = typeof query === 'string' ? query : inputValue || label || '';

    return (
      <Fragment>
        <InstSelect
          renderLabel={renderLabel}
          assistiveText={I18n.t('Use arrow keys to navigate options')}
          inputValue={showingInputValue}
          isShowingOptions={isShowingOptions}
          onBlur={this.handleBlur}
          onInputChange={this.handleInputChange}
          onRequestShowOptions={this.handleShowOptions}
          onRequestHideOptions={this.handleHideOptions}
          onRequestHighlightOption={this.handleHighlightOption}
          onRequestSelectOption={this.handleSelectOption}
          {...selectProps}
        >
          {this.renderOptions()}
          {loadOptionsState && this.renderLoadMoreOption(loadOptionsState)}
        </InstSelect>
        <Alert
          liveRegion={() => document.getElementById('alert')}
          liveRegionPoliteness="polite"
          screenReaderOnly
        >
          {announcement}
        </Alert>
      </Fragment>
    );
  }
}

export default Select;
