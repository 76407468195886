import { AccessibleContent } from '@instructure/ui-a11y-content';
import { IconButton } from '@instructure/ui-buttons';
import { Calendar } from '@instructure/ui-calendar';
import { DateInput as InstDateInput } from '@instructure/ui-date-input';
import { IconArrowOpenStartSolid, IconArrowOpenEndSolid } from '@instructure/ui-icons';
import I18n from 'i18n-js';
import { DateTime } from 'luxon';
import React, { Component, ReactNode } from 'react';

import { Message } from '../types';
import { now } from '../util';

type Props = {
  renderLabel?: string | ReactNode;
  enablePastDates?: boolean;
  date: DateTime | '';
  width?: string;
  display?: string;
  messages?: Array<Message>;
  interaction?: string;
  layout?: string;
  timezone?: string;
  assistiveText?: string;
  onChange: (date: DateTime) => void;
};

type State = {
  value: string | null;
  selectedDate: DateTime;
  firstDate: DateTime;
  isShowingCalendar: boolean;
};

interface DateState {
  value: string | null;
  selectedDate: DateTime;
  firstDate: DateTime;
}

class DateInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const initialDate = (props.date ? props.date : DateTime.now()) as DateTime;

    this.state = {
      value: props.date ? props.date.toISODate() : '',
      selectedDate: initialDate,
      firstDate: initialDate.startOf('month'),
      isShowingCalendar: false,
    };
  }

  getDateStates = (date: DateTime): DateState => {
    return {
      value: date.toISODate(),
      selectedDate: date,
      firstDate: date.startOf('month'),
    };
  };

  parseDate(text: string): DateTime {
    const { date, timezone } = this.props;

    return DateTime.fromFormat(text, 'yyyy-MM-dd', {
      zone: timezone || (date && date.zoneName) || DateTime.local().zoneName,
    });
  }

  handleDayClick = (
    e: React.SyntheticEvent<HTMLButtonElement>,
    { date }: { date: string },
  ): void => {
    const { onChange } = this.props;
    const parsed = this.parseDate(date);

    this.setState(this.getDateStates(parsed));
    onChange(parsed);
  };

  handleChange = (
    e: React.SyntheticEvent<HTMLInputElement>,
    { value }: { value: string },
  ): void => {
    const { onChange } = this.props;
    const parsed = this.parseDate(value);

    if (parsed.isValid) {
      this.setState(this.getDateStates(parsed));
      onChange(parsed);
    } else {
      this.setState({
        value,
      });
    }
  };

  handleShowCalendar = (): void => {
    this.setState(({ selectedDate }) => {
      return {
        isShowingCalendar: true,
        firstDate: selectedDate.startOf('month'),
      };
    });
  };

  handleHideCalendar = (): void => {
    this.setState(({ selectedDate }) => {
      return {
        isShowingCalendar: false,
        value: selectedDate.toISODate(),
      };
    });
  };

  handleSelectNextDay = (): void => {
    const { onChange } = this.props;

    this.setState(({ selectedDate }) => {
      const date = selectedDate.plus({
        days: 1,
      });

      onChange(date);
      return this.getDateStates(date);
    });
  };

  handleSelectPrevDay = (): void => {
    const { onChange } = this.props;

    this.setState(({ selectedDate }) => {
      const date = selectedDate.minus({
        days: 1,
      });

      onChange(date);
      return this.getDateStates(date);
    });
  };

  handleRenderNextMonth = (): void => {
    this.setState(({ firstDate }) => {
      return {
        firstDate: firstDate.plus({
          months: 1,
        }),
      };
    });
  };

  handleRenderPrevMonth = (): void => {
    this.setState(({ firstDate }) => {
      return {
        firstDate: firstDate.minus({
          months: 1,
        }),
      };
    });
  };

  renderButton = (icon: ReactNode, text: string): ReactNode => (
    <IconButton screenReaderLabel={text} withBackground={false} withBorder={false} size="small">
      {icon}
    </IconButton>
  );

  renderNavigationLabel = (): ReactNode => {
    const { firstDate } = this.state;

    return (
      <span>
        <div>{firstDate.year}</div>
        <div>{firstDate.monthLong}</div>
      </span>
    );
  };

  renderWeekdayLabels = (): ReactNode => {
    const today = now();

    return Array.from(Array(7), (_, index) => {
      // start from Sunday
      const weekDate = today.set({
        weekday: index,
      });

      return (
        <AccessibleContent alt={weekDate.weekdayLong}>
          {weekDate.toFormat('ccccc')}
        </AccessibleContent>
      );
    });
  };

  renderDays(): ReactNode {
    const { enablePastDates } = this.props;
    const { selectedDate, firstDate } = this.state;
    const firstSunday = firstDate.startOf('week').minus({
      days: 1,
    });
    const today = now().setZone(selectedDate.zoneName === null ? undefined : selectedDate.zoneName);

    return Array.from(Array(Calendar.DAY_COUNT), (_, index) => {
      const calDate = firstSunday.plus({
        days: index,
      });
      const value = calDate.toISODate();

      return (
        <InstDateInput.Day
          key={value}
          date={value}
          label={calDate.toLocaleString(DateTime.DATE_FULL)}
          interaction={
            calDate.diffNow('days').days < -1 && !enablePastDates ? 'disabled' : 'enabled'
          }
          isSelected={calDate.hasSame(selectedDate, 'day')}
          isToday={calDate.hasSame(today, 'day')}
          isOutsideMonth={!calDate.hasSame(firstDate, 'month')}
          onClick={this.handleDayClick}
        >
          {calDate.day}
        </InstDateInput.Day>
      );
    });
  }

  render(): ReactNode {
    const { renderLabel, width, display, messages, interaction, layout } = this.props;
    const { value, isShowingCalendar } = this.state;

    return (
      <InstDateInput
        renderLabel={renderLabel || I18n.t('Date')}
        value={value}
        layout={layout}
        onChange={this.handleChange}
        isShowingCalendar={isShowingCalendar}
        onRequestShowCalendar={this.handleShowCalendar}
        onRequestHideCalendar={this.handleHideCalendar}
        onRequestSelectNextDay={this.handleSelectNextDay}
        onRequestSelectPrevDay={this.handleSelectPrevDay}
        onRequestRenderNextMonth={this.handleRenderNextMonth}
        onRequestRenderPrevMonth={this.handleRenderPrevMonth}
        renderPrevMonthButton={this.renderButton(IconArrowOpenStartSolid, I18n.t('Previous month'))}
        renderNextMonthButton={this.renderButton(IconArrowOpenEndSolid, I18n.t('Next month'))}
        renderNavigationLabel={this.renderNavigationLabel()}
        renderWeekdayLabels={this.renderWeekdayLabels()}
        assistiveText={this.props.assistiveText}
        width={width}
        display={display}
        messages={messages}
        interaction={interaction}
      >
        {this.renderDays()}
      </InstDateInput>
    );
  }
}

export default DateInput;
